.button {
  --bg-color: var(--clr-white);
  --border-color: var(--clr-primary);
  --color: var(--clr-primary);

  color: var(--color);
  background-color: var(--bg-color);
  border: 2px solid var(--border-color);

  padding: 0.5em 2em;
  border-radius: var(--border-radius, 15px);

  cursor: pointer;
  font-weight: 600;
  transition: box-shadow ease 0.2s;

  &:focus-visible {
    outline: 2px solid var(--border-color);
    outline-offset: 2px;
  }

  &:hover {
    box-shadow: inset 0px 0 6px 2px rgba(0, 0, 0, 0.3);
  }
  &--primary {
    --bg-color: var(--clr-primary);
    --color: var(--clr-white);

    &:focus,
    &:active,
    &:hover {
      color: var(--clr-white);
    }
  }

  &--secondary {
    --bg-color: var(--clr-secondary);
    --border-color: var(--clr-secondary);
    --color: var(--clr-white);
  }
}

.gdpr-popover__trigger {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  border: 0;
  background-color: rgb(0, 157, 227);
  z-index: 1000;
  cursor: pointer;
  border-radius: 9999px;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 2.5s blink infinite ease-out;
  transition: transform 0.16s linear, opacity 0.08s linear;
  animation-delay: 5s;

  .gdpr-popover__svg {
    min-height: 28px;
    min-width: 32px;

    & path {
      min-height: 28px;
      min-width: 32px;
    }
  }

  &::after {
    content: '1';
    position: absolute;
    top: -4px;
    right: -4px;
    padding: 0.15rem 0.5rem;
    border-radius: 99px;
    z-index: 1001;
    display: block;
    background-color: red;
    color: white;
    font-size: 0.875rem;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.65;
  }
  to {
    opacity: 1;
  }
}
